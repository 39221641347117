import React from 'react';
import { connect } from 'react-redux';
import { logout, selectIsLoading } from 'data/user';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/Loader';


const Logout = ({ logout }) => {
    useEffect(() => {
    const referrer = document.referrer;
    if (referrer && referrer.includes('login')) {
        window.location.reload(false);
    }
    logout('manual');
  }, []);
  return (<Loader />)
}

export default withRouter(connect((state) => ({
    isLoading: selectIsLoading(state),
  }), {
    logout,
  })(Logout));

